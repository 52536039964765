import React from 'react';
import classes from '../Table.module.scss';
import clsx from 'classnames';
import { TooltipContainer } from '../../Tooltip/TooltipContainer';
import MainContent from '../MainContent/MainContent';
import User from 'services/user';
import { RUSSIAN_LOCALE } from 'utils';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { observer } from 'mobx-react-lite';
import { getTrimmedText } from 'tools/utils';

interface ContainerContentProps {
  value: any;
  handleShowModalOnButton?: (data: any) => void;
}

const ContainerContent = observer(
  ({ value, handleShowModalOnButton }: ContainerContentProps) => {
    const { isMediaTablet } = useWindowWidth();
    return (
      <div
        className={clsx(
          classes.table__cell__container,
          isMediaTablet && value?.time && classes.table__cell__container__mob
        )}
      >
        <div className={clsx(classes.table__header, value?.className)}>
          {value?.currency?.symbol && (
            <span className={classes.currency}>{value?.currency?.symbol}</span>
          )}

          <MainContent value={value} handleDownload={User.handleDownloadFile} />
        </div>

        {value?.description && (
          value?.description?.tooltip ? (
            <TooltipContainer
              text={getTrimmedText(value.description.text, 600)}
              position={isMediaTablet ? 'bottom' : 'right'}
              className={classes.table__cell__withTooltip__container}
              classNameTooltip={clsx(
                classes[`table__cell__withTooltip__tooltip__${isMediaTablet ? 'bottom' : 'right'}`],
                !isMediaTablet && classes[`table__cell__withTooltip__tooltip__right--additional`]
              )}
              tooltipInner={
                classes[`table__cell__withTooltip__tooltip-inner__${isMediaTablet ? 'bottom' : 'right'}`]
              }
            >
              <div className={value?.description?.className}>
                {value?.description?.text}
              </div>
            </TooltipContainer>
          ) : (
            <div className={value?.description?.className}>
              {value?.description?.text}
            </div>
          )
        )}


        {value?.time && (
          <div key="time" className={classes[value?.time?.className]}>
            {isMediaTablet && <span className={classes.comma}>,</span>}
            <span>{value?.time?.text}</span>
          </div>
        )}

        {value?.button && (
          <button
            className={clsx(
              classes.table__button,
              value?.button?.className?.length &&
                String(value?.button?.className),
              value?.button?.cancelled && classes.table__button_cancelled,
              RUSSIAN_LOCALE && classes[value?.button?.status]
            )}
            disabled={value?.button?.disabled}
            onClick={() =>
              handleShowModalOnButton
                ? handleShowModalOnButton([
                    {
                      serial_id: value?.serial_id,
                      project_number: value?.project_number,
                      amount: value?.amount,
                      project_name: value?.project_name,
                    },
                  ])
                : User.handleDownloadFile(
                    value?.button?.filePath as string,
                    value?.button?.text
                  )
            }
          >
            {value?.button?.text}
          </button>
        )}
      </div>
    );
  }
);

export default ContainerContent;
