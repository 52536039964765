import { Request } from 'tools/request';

export async function CheckCountry() {
  const result = await Request({
    type: 'GET',
    url: `${process.env.REACT_APP_API}/check-country/`,
    isProtected: false, 
  });

  return result;
}

