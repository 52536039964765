import _ from 'lodash';
import { userStore } from './stores/userStore';
import { marketPlaceStore } from './stores/marketPlaceStore';
import { cartStore } from './stores/cartStore';
import { authStore } from './stores/authStore';
import { growthStore } from './stores/growthProgram';
import { CLIENT_ROLE } from './types/user';

export const serializeToURI = function (obj: any): string {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export function getCookie(name: string): string | null {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = _.trim(cookies[i]);
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function setCookie(name: string, value: string, days: number): void {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export function isPromise(p: any) {
  if (
    typeof p === 'object' &&
    typeof p.then === 'function' &&
    typeof p.catch === 'function'
  ) {
    return true;
  }

  return false;
}

export const requestData = async (options: any, params?: any) => {
  const {
    services = false,
    invoices = false,
    projects = false,
    user = false,
    cart = false,
    types = false,
    serviceItem = false,
    auth = false,
    projectInvoices = false,
    projectActs = false,
    projectInfo = false,
    increment = false,
    dashboardProjects = false,
    client = false,
    favorites = false,
  } = options;

  userStore.loadData(true);
  // await cartStore.loadCart();
  const isServicesFull = marketPlaceStore?.services?.length;
  const isInvoicesFull = userStore?.invoices?.length;
  const isProjectsFull = userStore?.projects?.length;
  const isCartFull = cartStore?.cart?.id;
  const isTypesFull = marketPlaceStore?.businessTypes?.length;
  const isAuthFull = authStore?.accessToken;
  const isDashboardProjectsFull = userStore?.dashboardsProjects?.length > 0;

  const promises = [
    client && (await userStore.loadClient()),
    user ? await userStore.loadUser() : {},
    isServicesFull && !services
      ? {}
      : services
        ? await marketPlaceStore.loadServices()
        : {},
    isInvoicesFull && !invoices
      ? {}
      : invoices &&
          userStore?.client?.id &&
          userStore.client?.permission?.type === CLIENT_ROLE.ADMIN
        ? await userStore.loadInvoices(userStore.client.id)
        : {},
    isProjectsFull && !projects
      ? {}
      : projects && userStore?.client?.id
        ? await userStore.loadProjects(userStore.client.id)
        : {},
    isCartFull && !cart ? {} : cart ? await cartStore.loadCart() : {},
    isTypesFull && !types
      ? {}
      : types
        ? await marketPlaceStore.loadTypes()
        : {},
    serviceItem ? await marketPlaceStore.loadSingleService(params?.id) : {},
    isAuthFull && auth ? {} : auth ? await authStore.verifyAuth() : {},
    projectInfo ? await userStore.loadProjectData(params?.id) : {},
    increment ? await marketPlaceStore.incrementServiceViews(params?.id) : {},
    projectInvoices ? await userStore.loadProjectInvoices(1, params?.id) : {},
    isDashboardProjectsFull && !dashboardProjects
      ? {}
      : dashboardProjects
        ? await userStore.loadDashboardProjects()
        : [],
    favorites ? await marketPlaceStore.loadFavorites() : {},
    projectActs && userStore?.client?.id
      ? await userStore.loadProjectActs(userStore.client.id, { page: 1 })
      : {},
  ];

  try {
    if (!authStore.isServerError) {
      const results = await Promise.all(promises);
      userStore.loadData(false);

      return {
        services: results[0],
        invoices: results[1],
        projects: results[2],
        cart: results[3],
        types: results[4],
      };
    }
  } catch (error) {
    console.error('Error loading data:', error);
    throw error;
  }
};

export const RUSSIAN_LOCALE = process.env.REACT_APP_PLATFORM_TYPE === 'ru';
// export const RUSSIAN_LOCALE = true;
