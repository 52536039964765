import { routers } from 'constants/routers';
import { useAuthStore } from 'contexts/AuthContext';
import { useCartStore } from 'contexts/CartContext';
import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { useUserStore } from 'contexts/UserContext';
import { useOpenMenu } from 'hooks/useOpenMenu';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { toJS } from 'mobx';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChangeUser } from 'services/api/changeUser';
import { GetCountries } from 'services/api/getCountries';
import { GetCurrencies } from 'services/api/getCurrencies';
import { ReloadStores } from 'tools/reloadStores';
import { ShowNotification } from 'tools/showNotification';
import { Currency } from 'types/project';
import { Country, OnSaveLocalPreferencesData } from 'types/user';
import { CLIENT_ROLE } from 'types/user'
import { RUSSIAN_LOCALE } from 'utils';
import { languages } from 'constants/languages';

export const useHeader = () => {
  const { i18n, t } = useTranslation();
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const cartStore = useCartStore();
  const marketPlaceStore = useMarketPlaceStore();
  const { isMediaTablet, width } = useWindowWidth();
  const navigate = useNavigate();

  const { currency, country } = userStore?.user || {};

  
  const showIdLanguage =
      userStore.client?.permission?.type === CLIENT_ROLE.MANAGER
  
  const filteredLanguages = showIdLanguage
      ? languages
      : languages.filter((lang) => lang.lang_code !== 'id');

  const [active, setActive] = useState(false);
  const [cookies, setCookies] = useCookies(['access', 'refresh']);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [userCountry, setUserCountry] = useState<Country>(country);
  const [userCurrency, setUserCurrency] = useState<Currency>(currency);
  const [countries, setCountries] = useState<Country[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [isOpenLocalPreferencesModal, setIsOpenLocalPreferencesModal] =
    useState(false);
  const [isSuccessLocalPreferences, setIsSuccessLocalPreferences] =
    useState(false);
  const [isDisabledLocalPreferences, setIsDisabledLocalPreferences] =
    useState(false);

  const [path, setPath] = useState('');
  const [userName, setUserName] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [bottomMenuSize, setBottomMenuSize] = useState(56);

  useEffect(() => {
    isMediaTablet ? setPath(routers.account) : setPath(routers.personal);
  }, [isMediaTablet]);

  useEffect(() => {
    if (cartStore?.fixedButtonSize && isMediaTablet) {
      setBottomMenuSize(cartStore?.fixedButtonSize);
    }
  }, [cartStore?.fixedButtonSize]);

  const openMenu = () => {
    setActive(true);
  };

  const closeMenu = () => {
    setActive(false);
  };

  useOpenMenu(active);
  useOpenMenu(openSearch);

  const getParams = () => {
    if (userStore.user) {
      setUserName(userStore.user.first_name);
      setIsAdmin(userStore.user.is_superuser);
      setIsManager(userStore.user.is_staff);
      setUserCountry(userStore.user.country);
      setUserCurrency(userStore.user.currency);
    }
  };

  useEffect(() => {
    if (authStore.isAuth) {
      getParams();
    }
  }, [userStore.user]);

  let isActiveLink = false;
  const location = useLocation();

  const routersMatch = [
    routers.account,
    routers.personal,
    routers.business,
    routers.changePassword,
  ];

  routersMatch.includes(location.pathname)
    ? (isActiveLink = true)
    : (isActiveLink = false);

  const clearStore = () => {
    cartStore.clearStore();
    userStore.clearStore();
    marketPlaceStore.clearStore();
  };

  const resetCookies = async () => {
    clearStore();
    await authStore.logout();
    RUSSIAN_LOCALE && navigate(routers.login, { state: { isLogout: true } });
  };

  const handleOpenLocalPreferencesModal = () => {
    getCountries();
    getCurrencies();
    setIsOpenLocalPreferencesModal(true);
  };

  const handleCloseLocalPreferencesModal = () => {
    setIsOpenLocalPreferencesModal(false);
  };

  const getCountries = async () => {
    try {
      const response = await GetCountries({
        access: cookies.access,
        refresh: cookies.refresh,
      });
      setCountries(response);
    } catch (error) {
      setCountries([]);
    }
  };

  const getCurrencies = async () => {
    try {
      const response = await GetCurrencies({
        access: cookies.access,
      });
      setCurrencies(response);
    } catch (error) {
      setCurrencies([]);
    }
  };

  useEffect(() => {
    if (RUSSIAN_LOCALE) setIsSuccessLocalPreferences(true);
    if (userStore?.user?.country && userStore?.user?.currency) {
      setIsSuccessLocalPreferences(true);
    }
  }, [userStore.user]);

  const handleSendLocalPreferences = async (
    data: OnSaveLocalPreferencesData
  ) => {
    setIsDisabledLocalPreferences(true);
    try {
      const response = await ChangeUser({
        access: cookies.access,
        data: data,
      });
      userStore.addUser(response.user);
      setUserCountry(response.user.country);
      setUserCurrency(response.user.currency);
      setIsSuccessLocalPreferences(true);
      ReloadStores();
      userStore.setLocaleChanged(true);
      i18n.changeLanguage(response.user.language.lang_code, (error, t) => {
        if (error) {
          ShowNotification({
            type: 'error',
            children: t('An error occurred. Please try again later'),
          });
        } else {
          ShowNotification({
            type: 'success',
            children: t('Changes saved successfully'),
          });
        }
      });
      setIsOpenLocalPreferencesModal(false);
    } catch (error) {
      console.error(error);
      ShowNotification({
        type: 'error',
        children: t('An error occurred. Please try again later'),
      });
    }
    setIsDisabledLocalPreferences(false);
  };

  const goToGrowthProgram = () => {
    navigate('/programma-rosta');
  };

  const goToKokocID = () => {
    window.location.href = `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_URL}`;
  };

  const userClient = !!userStore?.user?.client;

  const isConfirmedEmail = userStore?.user?.email_confirmed;
  const isShowHeaderButtons = width <= 1024 && !active;
  const isShowHeaderAdminList = width <= 1024 && active;
  const isDisableHeader =
    !isSuccessLocalPreferences ||
    !userStore?.user?.email_confirmed ||
    authStore.isServerError;
  const modalListItems = [
    {
      label: t('Currency'),
      value: userStore.user?.currency?.char_code,
    },
    {
      label: t('Country'),
      value: userCountry?.name,
    },
    {
      label: t('Language'),
      value: userStore.user?.language?.name,
    },
  ];
  const isTestUser =
    userStore?.user?.id === 'demo@platform.kokoc' ||
    userStore?.user?.id === 753 ||
    userStore.user?.email === 'demo@platform.kokoc' ||
    userStore.user?.email === 'shy.elva@yandex.ru ';

  return {
    active,
    isDisableHeader,
    setOpenSearch,
    openSearch,
    isConfirmedEmail,
    openMenu,
    closeMenu,
    width,
    modalListItems,
    handleOpenLocalPreferencesModal,
    path,
    userName,
    isSuccessLocalPreferences,
    isActiveLink,
    isManager,
    isAdmin,
    goToKokocID,
    goToGrowthProgram,
    resetCookies,
    isTestUser,
    handleSendLocalPreferences,
    handleCloseLocalPreferencesModal,
    isOpenLocalPreferencesModal,
    setIsOpenLocalPreferencesModal,
    userCurrency,
    userCountry,
    currencies,
    countries,
    bottomMenuSize,
    isShowHeaderButtons,
    isDisabledLocalPreferences,
    isShowHeaderAdminList,
    userClient,
    filteredLanguages,
  };
};
