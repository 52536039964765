const langSelector = (name: string, i18n: { language: string }) =>
  `${name}_${i18n.language}`?.length ? `${name}_${i18n.language}` : name;

export const getLangName = (
  parent: any,
  child: string,
  i18n: { language: string }
) => parent?.[langSelector(child, i18n)] || parent?.[child];

// Makes a calculation and displays the correct ending. As an example, you need to pass the words 1 - "book", 2 - "books", 5 - "books"
export const declOfNum = (
  num: number,
  titles: [string, string, string]
): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
  ];
};

export const shuffleArray = <T>(array: T[]): T[] => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const decodeHtmlEntities = (html: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};


export const getTrimmedText = (text: string, maxLength: number = 600): string => {
  if (!text) return '';
  return text.length > maxLength ? text.slice(0, maxLength) + '…' : text;
};