import clsx from 'clsx';
import classes from 'containers/CardsContainer/ProductList/ProductCard/ProductCard.module.scss';
import { useCartStore } from 'contexts/CartContext';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button } from '../index';
import { SolutionType } from 'types/solutions';
import { pushOneEventToDataLayer } from 'tools/analytics';

const AddToCartButton = observer(
  ({ data, disabled = false, sourceType }: { data: SolutionType; disabled?: boolean; sourceType?: 'catalog' | 'favorites' | 'recommended' | 'card' }) => {
    const cartStore = useCartStore();
    const count = cartStore.getServiceCountInCart(data?.id as number);
    const [countInCart, setCountInCart] = useState(count || 0);
    const [isDisabledButton, setDisabledButton] = useState(disabled);

    useEffect(() => {
      setCountInCart(count);
    }, [count, data]);

    const addToCart = async () => {
      if (!isDisabledButton) {
        setDisabledButton(true);
        try {
          await cartStore.addToCart({
            serviceId: data.id as number,
          });

          let eventName = 'clickButtonAddToCartCard';

          if (sourceType === 'catalog') {
            eventName = 'clickButtonAddToCartCatalog';
          } else if (sourceType === 'favorites') {
            eventName = 'clickButtonAddToCartFavorites';
          } else if (sourceType === 'recommended') {
            eventName = 'clickButtonAddToCartRecom';
          }

          pushOneEventToDataLayer(eventName);
        } finally {
          setDisabledButton(false);
        }
      }
    };

    const removeFromCart = async () => {
      if (!isDisabledButton) {
        setDisabledButton(true);
        try {
          await cartStore.removeFromCart({
            serviceId: data.id as number,
          });

          let eventName = 'clickButtonRemoveFromCartCard';

          if (sourceType === 'catalog') {
            eventName = 'clickButtonRemoveFromCartCatelog';
          } else if (sourceType === 'favorites') {
            eventName = 'clickButtonRemoveFromCartFavorites';
          } else if (sourceType === 'recommended') {
            eventName = 'clickButtonRemoveFromCartRecom';
          }

          pushOneEventToDataLayer(eventName);
        } finally {
          setDisabledButton(false);
        }
      }
    };

    return (
      <Button
        onClick={countInCart > 0 ? undefined : addToCart}
        theme="primary"
        className={clsx(
          classes.button,
          countInCart > 0 ? classes['in-cart'] : classes['not-in-cart'],
          classes.add_to_cart_btn
        )}
        disabled={isDisabledButton}
      >
        {countInCart > 0 ? (
          <>
            <div className={classes['in-cart__btn']} onClick={removeFromCart}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M4.58331 11H17.4166"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <span className={classes.textAddBtn}>{t('In your Cart')}</span>

            <div
              className={clsx(
                classes.counter,
                countInCart > 9 && classes.counterMedium
              )}
            >
              {countInCart}
            </div>
            <div className={classes['in-cart__btn']} onClick={addToCart}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M11 4.58337V17.4167M4.58331 11H17.4166"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </>
        ) : (
          <span className={classes.textAddBtn}>{t('Add to Cart')}</span>
        )}
      </Button>
    );
  }
);

export default AddToCartButton;
