import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
// Libs
import { pagination as getPaginationList } from 'tools/pagination';
import { ArrowPagination } from 'components/shared/Icons/ArrowPagination';
// Styles
import classes from './Pagination.module.scss';
import { PaginationProps } from './Pagination.props';

export const Pagination = ({
  className,
  currentPage,
  totalPages,
  simbol,
  setPageNumber,
  extraAction,
  prevPage,
  nextPage,
  clearSeeMore,
}: PaginationProps): ReactElement => {
  const [currentPagePagination, setCurrentPagePagination] =
    useState(currentPage);


  useEffect(() => {
    setCurrentPagePagination(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (extraAction) {
      extraAction();
    }
  }, [currentPagePagination, extraAction]);

  const onClick = useCallback(
    (page: number) => {
      clearSeeMore && clearSeeMore();
      setPageNumber(page);
    },
    [setPageNumber]
  );

  const paginationList = useMemo(
    () =>
      getPaginationList({
        currentPage: currentPagePagination,
        totalPages,
        simbol,
      }),
    [currentPagePagination, totalPages, simbol]
  );




  return (
    <div className={clsx(className, classes.wrapper)}>
      <button
        className={clsx(classes.button, classes.button_prev)}
        disabled={currentPagePagination === 1}
        onClick={() => {
          onClick(currentPagePagination - 1);
          prevPage && prevPage();
        }}
      >
        <ArrowPagination fill="currentColor" rotate="left" />
      </button>

      <div className={clsx(classes['wrapper-pagination'])}>
        {paginationList.map((item, index) => (
          <div
            key={index}
            className={clsx(
              classes.item,
              typeof item !== 'number' && classes.symbol,
              currentPagePagination === item && classes.item_active
            )}
            onClick={() => {
              onClick(Number(item));
              extraAction && extraAction();
            }}
          >
            {item}
          </div>
        ))}
      </div>

      <button
        className={clsx(classes.button, classes.button_next)}
        disabled={currentPagePagination === totalPages}
        onClick={() => {
          onClick(currentPagePagination + 1);
          nextPage && nextPage();
        }}
      >
        <ArrowPagination fill="currentColor" />
      </button>
    </div>
  );
};
