import Cookies from 'js-cookie';

export const pushToDataLayer = (
  category: string,
  action: string,
  event: string
) => {
  if (process.env.REACT_APP_SEND_EVENTS_TO_DATALAYER === 'true') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      eCategory: category,
      eAction: action,
      event: event,
    });
  }
};

export const pushSimpleEventToDataLayer = (
  event: string,
  parameter: string
) => {
  if (process.env.REACT_APP_SEND_EVENTS_TO_DATALAYER === 'true') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
      event_parameter: parameter,
    });
  }
};

export const pushOneEventToDataLayer = (event: string) => {
  if (process.env.REACT_APP_SEND_EVENTS_TO_DATALAYER === 'true') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event });
  }
};

export const getGaClientId = () => {
  let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
  const raw = match ? decodeURIComponent(match[1]) : null;
  if (raw) {
    match = raw.match(/(\d+\.\d+)$/);
  }
  const gacid = match ? match[1] : null;
  if (gacid) {
    return gacid;
  }
};

export const getYmClientId = () => Cookies.get('_ym_uid');

export const sendUserIdToGA = (userId: number) => {
  if (typeof window !== 'undefined' && typeof window?.gtag === 'function') {
    window.gtag('config', 'G-TM7BTSKQ5W', {
      user_id: userId,
    });
  } else {
    console.warn('Google Analytics (gtag) is not initialized');
  }
};
