import React, { FC, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { RegistrationForm } from './RegistrationForm';
import { SwiperBlock } from './SwiperBlock';
import { Link } from '../../components/shared/Link';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { useAuthStore } from 'contexts/AuthContext';
import { useUserStore } from 'contexts/UserContext';
import { useTranslation } from 'react-i18next';

import logo from 'assets/img/logo.png';
import logo_ru from 'assets/img/logo_ru.svg';
import success from 'assets/img/Registration/success.png';

import classes from './Registration.module.scss';
import { Success } from './Success';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { languages } from 'constants/languages';
import { ShowNotification } from 'tools/showNotification';
import { RUSSIAN_LOCALE } from '../../utils';
import { setAccessToken, setRefreshToken } from '../../services/http';
import clsx from 'clsx';
import { toJS } from 'mobx';
import { ArrowNarrowLeft } from 'components/shared/Icons/ArrowNarrowLeft';
import { Language, UserTokenSuccessTypes } from '../../types/auth';
// import { getFilteredLanguages } from 'tools/languageFilter';

export const RegistrationContainer = () => {
  const [step, setStep] = useState(1);
  const [sendEmail, setSendEmail] = useState('');
  const [successData, setSuccessData] = useState<UserTokenSuccessTypes | null>(
    null
  );

  const authStore = useAuthStore();
  const userStore = useUserStore();

  const { i18n, t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const changeLanguage = (langObj: Language) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.has('lng')) {
      newSearchParams.delete('lng');
      setSearchParams(newSearchParams);
    }
    i18n.changeLanguage(langObj.lang_code);
    localStorage.setItem('kitGlobalLng', langObj.lang_code);
  };

  const resendEmail = async () => {
    const result = await authStore.loginByTokens(
      successData?.access || '',
      successData?.refresh || ''
    );
    if (result) {
      const user = await userStore.loadUser();
      if (!user?.email_confirmed) {
        await userStore.sendRegistrationCompleteEmail();
        ShowNotification({
          type: 'success',
          children: t(
            'Email successfully sent. It may take up to 2 minutes to deliver'
          ),
        });
      } else {
        ShowNotification({
          type: 'error',
          children: t('Your email is already confirmed'),
        });
      }
      setAccessToken('');
      setRefreshToken('');
      authStore.setAuth(false);
      return;
    } else {
      ShowNotification({
        type: 'error',
        children: t('Something went wrong, please try later'),
      });
    }
  };

  const kid_user_info = toJS(authStore).kid_user_info;

  const handleBack = () => {
    window.scrollTo(0, 0);
    setStep(1);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invite = queryParams.get('invite');

  return (
    <>
      <TitleAndDescription pageName="Registration" />
      <div className={classes.container}>
        <Link
          href={RUSSIAN_LOCALE ? '#' : `${process.env.REACT_APP_MAIN_SITE_URL}`}
        >
          <img
            src={RUSSIAN_LOCALE ? logo_ru : logo}
            alt=""
            className={clsx(classes.logo, RUSSIAN_LOCALE && classes.logo_ru)}
          />
        </Link>
        <div className={classes.left}>
          {step !== 3 ? (
            <SwiperBlock />
          ) : (
            <img src={success} alt="" className={classes.success} />
          )}
        </div>
        <div className={classes.right}>
          {!RUSSIAN_LOCALE && (
            <div className={classes.lang}>
              <MultiselectComponent
                // data={getFilteredLanguages()}
                data={languages}
                langSelect={true}
                defaultSelected={
                  languages[
                    languages.findIndex(
                      (lang) => lang.lang_code === i18n.language
                    )
                  ]?.id
                }
                setMultiselect={changeLanguage}
              />
            </div>
          )}
          {invite && (
            <div className={classes.invitation}>
              {t('Follow invitation link')}
            </div>
          )}

          {step !== 3 && !invite && (
            <div className={clsx(step === 2 && classes.backBtn)}>
              {step === 2 && (
                <ArrowNarrowLeft
                  onClick={handleBack}
                  className={classes.arrow}
                />
              )}
              <div className={clsx(classes.steps, step === 2 && classes.step2)}>
                {t('Step {{step}} of 2', { step: step })}
              </div>
            </div>
          )}
          {step !== 3 && (
            <div className={classes.title}>
              {step === 1 ? (
                <>
                  {t('Welcome to')}{' '}
                  <span className={classes.highlight}>
                    {t('KIT Global Platform')}
                  </span>
                </>
              ) : (
                t('Business details')
              )}
            </div>
          )}
          {step !== 3 && (
            <div className={classes.text}>
              {step === 1 && (
                <>
                  <span>
                    {t('Fill in your details to create your account')}
                  </span>
                  {!invite && (
                    <span>
                      {t('Already have an account')}?
                      <Link
                        className={classes.link}
                        href="/login"
                        query={invite ? { invite: invite } : undefined}
                      >
                        {t('Log in')}
                      </Link>
                    </span>
                  )}
                </>
              )}
              {step === 2 && (
                <span>
                  {t(
                    'Tell us more about your business to get more relevant recommendations'
                  )}
                </span>
              )}
            </div>
          )}
          {step !== 3 ? (
            <RegistrationForm
              setSendEmail={setSendEmail}
              step={step}
              setStep={setStep}
              setSuccessData={setSuccessData}
              kidUserInfo={kid_user_info}
            />
          ) : (
            <Success email={sendEmail} resendEmail={resendEmail} />
          )}
        </div>
      </div>
    </>
  );
};
